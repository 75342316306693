import './assets/style.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import TripPlan from './components/TripPlan';
import Header from './components/Header';
import Register from './components/Register';
import Login from './components/Login';
import Preview from './components/Preview';
import Blog from './components/Blog';
import ResetPassword from "./components/ResetPassword"
import ForgetPassword from './components/ForgotPassword';
import Profile from "./components/Profile";
import ProfileForgotPassword from './components/ProfileFogotPassword';
function App() {
  // const token = localStorage.getItem("token")
  // debugger
  return (
    <div className="App">
      <div className="header-container">
        <Header />
      </div>
      <Routes >
        <Route path="/" element={<Home />} />
        <Route path="/trip-plan" element={<TripPlan />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login/>} />
        <Route path="/preview" element={<Preview/>} />
        <Route path="/blog" element={<Blog/>} />
        <Route path="/forgot-password" element={<ForgetPassword />} />
        <Route path="/reset-password/:id" element={<ResetPassword/>} />
        <Route path="/profile" element={<Profile/>} />
        <Route path="/profile-forgot-password" element={<ProfileForgotPassword/>} />
      </Routes>
    </div>
  );
}

export default App;
