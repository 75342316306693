
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';

function ResetPassword() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [resMessage, setResMessage] = useState('');
  const [color, setColor] = useState('text-danger');

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    if (!password || !confirmPassword) {
      setResMessage('All fields are required.');
      setColor('text-danger');
      return;
    }

    if (password !== confirmPassword) {
      setResMessage('Passwords do not match');
      setColor('text-danger');
      return;
    }

    setLoading(true);

    

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/reset-password`, {
        newPassword: password,
        id: id,
      });

      if (response?.data && response?.data?.message==='Password reset successful') {
        setColor('text-success');
        setResMessage(response?.data?.message);

        setTimeout(() => {
          navigate('/login');
        }, 2000);
      } else {
        setColor('text-danger');
        setResMessage('Password reset failed');
      }
    } catch (error) {
      console.error(error);
      setColor('text-danger');
      setResMessage('Password reset failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="auth-container">
      <div className="container form-container login-form">
        <h1>Reset Password</h1>
        <form onSubmit={handlePasswordReset}>
          <div className="form-group">
            <label htmlFor="password">New password</label>
            <input
              className="form-control"
              type="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm password</label>
            <input
              className="form-control"
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
          </div>

          <div className="submit-btn">
            <button type="submit" disabled={loading}>
              Reset password
            </button>
          </div>
        </form>
      </div>
      <div className="account-box text-center mt-3">
        {loading ? (
          <ClipLoader />
        ) : (
          <div className={`account-box text-center ${color}`}>
            {resMessage}
          </div>
        )}
      </div>
    </div>
  );
}

export default ResetPassword;
