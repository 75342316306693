import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"

import BasicExample from "./profileDropdown"
import { useSelector } from "react-redux";

let Header = () => {
  const token = useSelector((state)=>state.auth.token)  
  
  // let token = localStorage.getItem("token");

  // let [resToken, setToken] = useState(false)
  // useEffect(() => {
  //   if (token) {
  //     setToken(true)
  //   } else {
  //     setToken(false)
  //   }
  // }, [token])

  return (
    <nav className="navbar navbar-expand-lg  navebarheader d-flex bg-light">
      <div className="container-fluid navebarheader1 ">
        <div className="header_section"></div>
        <div className="navbar-brand nav-link" >
          <Link to={"/"}><img src={require("../assets/images/rnd-logo-1.png")} alt="" /></Link>
        </div>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link" to={"/blog"}>Blog</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to={"/trip-plan"}>Trip Planner</Link>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link" to={"/preview"}>preview</Link>
            </li> */}
          </ul>
        </div>
        <div className="header_button">
          {token && token !== null ?
            <div className="dropdown">

              <img src={require("../assets/images/profile.png")} alt="" />
              <BasicExample />
            </div> : <div><Link className="nav-link" to={"/login"}>Sign In</Link></div>
          }
        </div>
      </div>
    </nav>
  )
}

export default Header