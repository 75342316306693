import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch } from 'react-redux';
import { logout } from '../store/actions/Auth';
import { Link, useNavigate } from "react-router-dom"
import { useState,useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';

function BasicExample() {
  // let userName=localStorage.getItem("userName")
  let navigate = useNavigate();
  const dispatch = useDispatch()
  const [smShow, setSmShow] = useState(false);

  const [userName,setUserName]=useState("")
  useEffect(() => {
    setSmShow(false)
  
    async function fetchData() {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/get-profile/${localStorage.getItem('id')}`
        );
        setUserName(response?.data?.data?.userName)
      }
      fetchData();
      },[]);

      let Logout=(props)=>{
        localStorage.removeItem('TripPlanData');
        localStorage.removeItem("id")
        dispatch(logout())
        navigate("/")
        setSmShow(props.status)
    }
    let NotLogout=()=>{
        setSmShow(false)
        // setSmShow(props.status)
    }
  let LogOut = () => {
    // localStorage.removeItem('TripPlanData');
    // localStorage.removeItem("id")
    // dispatch(logout())
    // navigate("/")
    setSmShow(true)


  }  
  let Forgot = () => {
    navigate("/profile-forgot-password")
  }

  return (

    <Dropdown>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
     { userName}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item as={Link} to="/profile"> <img src={require("../assets/images/edit.png")} alt=''/> Edit Profile</Dropdown.Item>
        <Dropdown.Item onClick={Forgot}><img src={require("../assets/images/reset-password.png")} alt=''/> Forgot password</Dropdown.Item>
        <Dropdown.Item onClick={LogOut}><img src={require("../assets/images/logout.png")} alt=''/> Log out</Dropdown.Item>
        {console.log("smShow",smShow)}


        
        </Dropdown.Menu>
        <Modal
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
        className='logout_modal'
      >
        <Modal.Header >
          <Modal.Title id="example-modal-sizes-title-sm">
           Are you sure you want to log out?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='modal_button'>
                <Button onClick={NotLogout}>No</Button>
                <Button onClick={Logout}>Yes</Button>
            </div>
        </Modal.Body>
      </Modal>
    
    </Dropdown>

  );
}

export default BasicExample;