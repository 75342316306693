import { useState } from "react";
import axios from 'axios'
import { Link, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners"
import ShowModal from "./modal"

let Register = () => {

  const [formData, setFormData] = useState({ username: '', email: '', password: '', mobile: '' });
  const [errors, setErrors] = useState({});
  const [responseData, setResponseData] = useState('');
  const [loading1, setLoading1] = useState(null)
  const [resColor, setColor] = useState(false);
 
  let inputHandler = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

  }

  const checkValidation = () => {
    const validationErrors = {};
    if (!formData.username) {
      validationErrors.username = 'Username field is required';
    } else if (!/^[a-zA-Z0-9_]+$/.test(formData.username)) {
      validationErrors.username = 'Please enter a valid username';
    }

    if (!formData.mobile) {
      validationErrors.mobile = 'Mobile number field is required';
    } else if (!/^[0-9]{10}$/.test(formData.mobile)) {
      validationErrors.mobile = 'Please enter a valid 10-digit mobile number';
    }

    if (!formData.email) {
      validationErrors.email = 'Email address field is required';
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      validationErrors.email = 'Please enter a valid email address';
    }

    if (!formData.password) {
      validationErrors.password = 'Password field is required';
    } else if (formData.password.length < 6) {
      validationErrors.password = 'Password must be at least 6 characters';
    }
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };


  let onsubmit = async (e) => {
    e.preventDefault();
    setLoading1(true)
    const isValid = checkValidation();
    if (!isValid) {
      setLoading1(false);
   
      return false
    }
    // setShowModal(false)
     
      setResponseData("")
    let response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/register`, formData);
   
    console.log(response?.data?.message);
    if (response?.data?.message === "User registered successfully") {
   
      setLoading1(false)
      setColor(false)
      setResponseData(response?.data?.message);
   
    
        setFormData({ ...formData, username: '', email: '', password: '', mobile: '' });
        
      // setTimeout(() => 
      //   navigate("/login");
      // }, 2000);
    } else {
      setResponseData(response?.data?.message)
      setLoading1(false)
      setColor(true)
    

    }

    

  }
  return (
    <div className="auth-container">
      <div className="container form-container register-form">
        <h1>Signup</h1>
        <form onSubmit={onsubmit}>
          <div className="form-group">
            <label htmlFor='username'>Username</label>
            <input
              type='text'
              name='username'
              id='username'
              value={formData.username}
              className="form-control"
              onChange={inputHandler}
            />
            {errors?.username && (
              <div className="error-message-box">
                <span >{errors?.username}</span>
              </div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor='email'>Email</label>
            <input
              className="form-control"
              type='text'
              value={formData.email}
              name='email'
              id='email'
              onChange={inputHandler}
            />
            {errors?.email && (
              <div className="error-message-box">
                <span >{errors?.email}</span>
              </div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor='mobile'>Mobile</label>
            <input
              className="form-control"
              type='number'
              value={formData.mobile}
              name='mobile'
              id='mobile'
              onChange={inputHandler}
            />
            {errors?.mobile && (
              <div className="error-message-box">
                <span >{errors?.mobile}</span>
              </div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor='password'>Password</label>
            <input
              className="form-control"
              type='password'
              name='password'
              value={formData.password}
              id='password'
              onChange={inputHandler}
            />
            {errors?.password && (
              <div className="error-message-box">
                <span >{errors?.password}</span>
              </div>
            )}
          </div>
          <div className="submit-btn">
            <button type='submit'>Signup</button>
          </div>

          <div className="account-box text-center mt-3">
            {loading1 ? <ClipLoader /> : resColor? <div className="account-box text-center text-danger">
              {responseData}
            </div> : <div className="account-box text-center text-success">
              {responseData}
            </div>}
          </div>
          <div className="account-box text-center mt-3">
            <span>Already have an account? <Link to="/login">Login</Link></span>
          </div>
           {!loading1? < ShowModal message={responseData}
            />:""}
        </form>
      </div>

    </div>
  )
}
export default Register;

// import { useState } from "react";
// import axios from 'axios'
// import { Link, useNavigate } from "react-router-dom";
// import { ClipLoader } from "react-spinners"
// import ShowModal from "./modal"

// let Register = () => {
//   const navigate = useNavigate()
//   const [formData, setFormData] = useState({ username: '', email: '', password: '', mobile: '' });
//   const [errors, setErrors] = useState({});
//   const [responseData, setResponseData] = useState('');
//   const [loading, setLoading] = useState(false)
//   const [resColor, setColor] = useState("text-danger");
//   const [showModal, setshowModal] = useState(false);
//   const [userName, setUserName] = useState("")

//   let inputHandler = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });

//   }

//   const checkValidation = () => {
//     const validationErrors = {};
//     if (!formData.username) {
//       validationErrors.username = 'Username field is required';
//     } else if (!/^[a-zA-Z0-9_]+$/.test(formData.username)) {
//       validationErrors.username = 'Please enter a valid username';
//     }

//     if (!formData.mobile) {
//       validationErrors.mobile = 'Mobile number field is required';
//     } else if (!/^[0-9]{10}$/.test(formData.mobile)) {
//       validationErrors.mobile = 'Please enter a valid 10-digit mobile number';
//     }

//     if (!formData.email) {
//       validationErrors.email = 'Email address field is required';
//     } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
//       validationErrors.email = 'Please enter a valid email address';
//     }

//     if (!formData.password) {
//       validationErrors.password = 'Password field is required';
//     } else if (formData.password.length < 6) {
//       validationErrors.password = 'Password must be at least 6 characters';
//     }
//     setErrors(validationErrors);
//     return Object.keys(validationErrors).length === 0;
//   };


//   let onsubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true)
//     const isValid = checkValidation();
//     if (!isValid) {
//       setLoading(false)
//       return false
//     }
//     setshowModal(false)
//       setUserName("")
//       setResponseData("")
//     let response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/register`, formData);
   
//     console.log(response?.data?.message);
//     if (response?.data?.message === "User create successfully") {
//       setshowModal(true)
//       setUserName(formData.username)
//       setLoading(false)
//       setColor("text-success")
//       setResponseData("User create successfully");
    
//         setFormData({ ...formData, username: '', email: '', password: '', mobile: '' });
        
//       // setTimeout(() => 
//       //   navigate("/login");
//       // }, 2000);
//     } else {
//       setResponseData(response?.data.message)
//       setLoading(false)

//     }

    

//   }
//   return (
//     <div className="auth-container">
//       <div className="container form-container register-form">
//         <h1>Signup</h1>
//         <form onSubmit={onsubmit}>
//           <div className="form-group">
//             <label htmlFor='username'>Username</label>
//             <input
//               type='text'
//               name='username'
//               id='username'
//               value={formData.username}
//               className="form-control"
//               onChange={inputHandler}
//             />
//             {errors?.username && (
//               <div className="error-message-box">
//                 <span >{errors?.username}</span>
//               </div>
//             )}
//           </div>
//           <div className="form-group">
//             <label htmlFor='email'>Email</label>
//             <input
//               className="form-control"
//               type='text'
//               value={formData.email}
//               name='email'
//               id='email'
//               onChange={inputHandler}
//             />
//             {errors?.email && (
//               <div className="error-message-box">
//                 <span >{errors?.email}</span>
//               </div>
//             )}
//           </div>
//           <div className="form-group">
//             <label htmlFor='mobile'>Mobile</label>
//             <input
//               className="form-control"
//               type='number'
//               value={formData.mobile}
//               name='mobile'
//               id='mobile'
//               onChange={inputHandler}
//             />
//             {errors?.mobile && (
//               <div className="error-message-box">
//                 <span >{errors?.mobile}</span>
//               </div>
//             )}
//           </div>
//           <div className="form-group">
//             <label htmlFor='password'>Password</label>
//             <input
//               className="form-control"
//               type='password'
//               name='password'
//               value={formData.password}
//               id='password'
//               onChange={inputHandler}
//             />
//             {errors?.password && (
//               <div className="error-message-box">
//                 <span >{errors?.password}</span>
//               </div>
//             )}
//           </div>
//           <div className="submit-btn">
//             <button type='submit'>Signup</button>
//           </div>

//           <div className="account-box text-center mt-3">
//             {loading ? <ClipLoader /> : resColor === 'text-danger' ? <div className="account-box text-center text-danger">
//               {responseData}
//             </div> : <div className="account-box text-center text-success">
//               {responseData}
//             </div>}
//           </div>
//           <div className="account-box text-center mt-3">
//             <span>Already have an account? <Link to="/login">Login</Link></span>
//           </div>
//            {showModal? < ShowModal message={responseData}
//             userName={userName}
//             status={showModal}
//             />:""}
//         </form>
//       </div>

//     </div>
//   )
// }
// export default Register;