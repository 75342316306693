export const loginSuccess = (token) => {

    return {
        type: 'LOGIN_SUCCESS',
        payload: token,
    };
};

export const logout = (token) => {
    return {
        type: 'LOGOUT',
        payload: token,
    };
};


