
import React, { useState, useEffect } from "react";
import dataTrip from '../tourData.json'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import { ClipLoader } from 'react-spinners';
import { useSelector } from "react-redux";
import Accordion from 'react-bootstrap/Accordion';

const containerStyle = {
  width: '100%'
};

const center = {
  lat: 30.704649,
  lng: 76.717873
};

function Preview({ travelData }) {
  let token = useSelector((state) => state.auth.token)

  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState(null); // You didn't define 'responseData'
  const navigate = useNavigate()
  const [readMore, setReadeMore] = useState(false)
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "",
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  useEffect(() => {
    setResponseData(dataTrip)
    // console.log(dataTrip)
    setLoading(false)
  }, [])

  // const [responseData, setResponseData] = useState(null); // Initialize as null
  //   useEffect(() => {
  // // function Preview() {
  //   async function fetchData() {
  //     try {
  //               //  console.log("prev",travelData)
  //       const res = await axios.post(`${process.env.BACKEND_URL}/chatGpt`, travelData);
  //       console.log("DatataType ",typeof res?.data.message);
  //         console.log("objMessage", res?.data?.message);
  //         console.log("objdata", res?.data);

  //         localStorage.setItem("responseData", res?.data?.message)
  //       // if (res?.data && typeof res?.data === 'string') {
  //       //   // Check if the response is a string (non-JSON)
  //       //   console.log("stringdata", res?.data);
  //       //   setResponseData(res?.data);
  //       //   setLoading(false);
  //       // } else if (res?.data && res?.data?.message) {
  //       //   const objData = JSON.parse(res?.data?.message);
  //       //   setResponseData(objData);
  //       //   setLoading(false);

  //       // }
  //       // console.log("started end");
  //     } catch (error) {
  //       console.log("catch err",);
  //       setLoading(true);
  //     }
  //   }
  //   fetchData();
  //     }, []);




  if (loading) {
    return (<div className="scaleLoader" role="status">
      <ClipLoader
        color="#9936d6"
        size={65}
        speedMultiplier={1}
      />
    </div>
    )
  }


  // if (error) {
  //   return <div> Error: {error.message}</div>; // Display an error message
  // }







  let ReadMore = () => {
    
    let TripPlanData = localStorage.getItem("TripPlanData");
    if (token && TripPlanData) {
      setReadeMore(true)
      setLoading(false)
    } else {
      setReadeMore(false)
      navigate("/login")
    }

  }



  return (
    <>
      <div className="preview_page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <div className="preview_box">
                <p className="date"> {responseData?.Date}</p>
                <h6>{responseData?.day} {responseData?.Place}</h6>
                <p> {responseData?.Budget}. {responseData?.EstimatedCost?.Activities.map(Activities => Activities?.Activities + ". ")}</p>
                <p> {responseData?.Description}</p>
              </div>
              <div className="preview_icon">
                <p className="icon_img"><img src={require("../assets/images/currency-svg.png")} alt="" />{responseData?.Currency}</p>
                <p className="icon_img"><img src={require("../assets/images/2-svg.png")} alt="" />1 USD=₹83.27</p>
                <p className="icon_img"><img src={require("../assets/images/Language-svg.png")} alt="" />{responseData?.Language?.join(", ")}</p>
                <p className="icon_img"><img src={require("../assets/images/vical-svg.png")} alt="" /> {responseData?.Vehicle?.join(", ")}</p>
                <p className="icon_img"><img src={require("../assets/images/wither-svg.png")} alt="" /> {responseData?.Temperature}</p>
                <p className="icon_img"><img src={require("../assets/images/1-svg.png")} alt="" /> {responseData?.TimeZone}</p>
              </div>
              <h2>Itinerary</h2>
              <button className="read_more" onClick={ReadMore}>Read More</button>
              {readMore ? <div >
                {responseData?.days?.map((day, index) => (
                  <div className="Itinerary-data" key={index}>
                        <Accordion defaultActiveKey={index}>
      <Accordion.Item eventKey={index}>
        <Accordion.Header> 
                    <h4>{day?.Day}</h4><br/>
      
                    <p> {day?.Date}</p>
                    </Accordion.Header>
                    <Accordion.Body>
                    <ul>
                      {day.place.map((place, placeIndex) => (
                        <li key={placeIndex}>
                          <h6> {place?.place}</h6>

                          {place?.description}

                          <p>{place?.distance}</p>
                        </li>
                      ))}
                    </ul>
        </Accordion.Body>
        </Accordion.Item>
    </Accordion>
                  </div>
                ))}
                <h3>Estimated Cost (USD)</h3>
                <div className="accommodation_section">
                  <h5 className="icon_img" > <img src={require("../assets/images/bad-svg.png")} alt="" />Accommodation</h5>

                  <ul className="accommodation_page">
                    {responseData?.EstimatedCost?.Accommodation.map((Accommodation, placeIndex) => (

                      <li key={placeIndex}>
                        <div className="hotel">
                          {Accommodation?.Accommodation}
                          <br />
                          {Accommodation?.Cost}
                        </div>
                      </li>

                    ))}
                  </ul>

                </div>

                <h5 className="icon_img" > <img src={require("../assets/images/vical-svg.png")} alt="" />Transportation</h5>
                <div className="accommodation_section">
                  <ul className="accommodation_page">
                    {responseData?.EstimatedCost?.Transportation.map((Transportation, placeIndex) => (
                      <li key={placeIndex}>
                        <div className="hotel">
                          {Transportation?.Transportation}
                          <br />
                          {Transportation?.Cost}
                        </div>
                      </li>
                    ))}
                  </ul>
                  <div />



                  <h5 className="icon_img" > <img src={require("../assets/images/food-svg.png")} alt="" />Food</h5>
                  <div className="accommodation_section">
                    <ul className="accommodation_page">
                      {responseData?.EstimatedCost?.Food.map((Food, placeIndex) => (
                        <li key={placeIndex}>
                          <div className="hotel">
                            {Food?.Food}
                            <br />
                            {Food?.Cost}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <h5 className="icon_img" > <img src={require("../assets/images/activities-svg.png")} alt="" />Activities</h5>
                  <div className="accommodation_section">
                    <ul className="accommodation_page">
                      {responseData?.EstimatedCost?.Activities.map((Activities, placeIndex) => (
                        <li key={placeIndex}>
                          <div className="hotel">
                            {Activities?.Activities}
                            <br />
                            {Activities?.Cost}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

              </div> : ""}
            </div>
            <div className="col-sm-6 map-box" >
            <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3430.1946584954053!2d76.70527277530024!3d30.712927586589974!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fee93193de911%3A0x5a5d7cc4c02503c2!2sWebsite%20Development%20-%20Wisdom%20Infosoft%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1699363100557!5m2!1sen!2sin"
        width="100%"
        height="650"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Google Map"
      ></iframe>
{/* 
              {isLoaded ? (
                <GoogleMap
                  mapContainerClassName="map-div"
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={10}
                  onLoad={onLoad}
                  onUnmount={onUnmount}
                >
                  <></>
                </GoogleMap>
              ) : <></>} */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Preview;