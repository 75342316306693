
import React, { useState,useRef, useEffect } from 'react';
import { json, useNavigate } from 'react-router-dom'
import DatePicker from "react-datepicker";
import axios from 'axios'
import Footer from './Footer';
import 'bootstrap/dist/css/bootstrap.min.css'
import "react-datepicker/dist/react-datepicker.css";
import citiesData from '../jsonData/placeName.json';
import Preview from './Preview';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';


const TripPlan = () => {
    const navigate = useNavigate()
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searchTerm1, setSearchTerm1] = useState('');
    const [searchResults1, setSearchResults1] = useState([]);
    const [travelDate, setTravelDate] = useState('');
    const [numOfDays, setNumOfDays] = useState(1);
    const [PlanupState, setPlanUp] = useState("");
    const [Activities, setActivities] = useState([]);
    const [tourGuides, setTourGuides] = useState("");
    const [foodType, setFoodType] = useState([]);
    const [response, setResponse] = useState('');
    const [Budget, setBudget] = useState("");
    const [travelWith, setTravelWith] = useState('');
    const [selectedActivities, setSelectedActivities] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showDropdown1, setShowDropdown1] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [travelData, setTravelData] = useState('');
    const [value, setValue] = useState("");
    const [errors, setErrors] = useState({});

    const testRef = useRef();
    const testRef1 = useRef();
    
   
    //   const [foucsText, setFocusText] = useState("");
   
       
    
    // clear localStorage
    localStorage.removeItem('myData');

    const autoCompleteRef = useRef();
    const inputRef = useRef();
    const options = {
        componentRestrictions: { country: "ng" },
        fields: ["address_components", "geometry", "icon", "name"],
        types: ["establishment"]
    };

    useEffect(() => {
        if (window.google) {
            autoCompleteRef.current = new window.google.maps.places.Autocomplete(
                inputRef.current,
                options
            );
        }
    }, []);

    const handleStartDestinationChange = async (value) => {
        
        // console.log("handleStartDestinationChange", value)
        const searchTerm1 = value.toLowerCase()
        // const searchTerm1 = value.toLowerCase().charAt(0).toUpperCase() + value.slice(1)
        setSearchTerm(searchTerm1);
        // for (let country in citiesData) {
        //     let city = citiesData[country];
        //     if (city.includes(searchTerm1)) {
        //         let filteredCities = city.filter((cityes) =>
        //             cityes.toLowerCase().includes(searchTerm1.toLowerCase())
        //         );
        //         setSearchResults({ country: country, city: filteredCities });
        //     }
        // }



        //   console.log(searchResults)
        //     setShowDropdown(true);
        // <GooglePlacesAutocomplete
        //     apiKey=''
        //     selectProps={{
        //         value,
        //         onChange: setValue(searchTerm1),
        //     }}

        // />
        //   console.log(value)


    };
    let SelectCities = (value) => {
        // console.log('value ', value);
        // setSearchTerm(searchResults)
        // setShowDropdown(false);
    }

    const handleEndDestinationChange = (value) => {
        const searchTerm1 = value.toLowerCase().charAt(0).toUpperCase() + value.slice(1)
        // const searchTerm1 = value.toLowerCase()
        setSearchTerm1(searchTerm1);
        for (let country in citiesData) {
            let city = citiesData[country];

            if (city.includes(searchTerm1)) {
                let filteredCities = city.filter((cityes) =>
                    cityes.toLowerCase().includes(searchTerm1.toLowerCase())
                );
                setSearchResults1({ country: country, city: filteredCities });
            }
        }
        setShowDropdown1(true)

    };

    let SelectCities1 = (value) => {
        // console.log('value ', value);
        setSearchTerm1(value + "," + searchResults1.country)
        setShowDropdown1(false)
    }

    const handleNumOfDaysChange = (value) => {
        if (value >= 1) {
            setNumOfDays(value);
            // console.log(value, travelDate)
        }
    };

    let PlanUp = (e) => {
        // console.log(e.target.value)
        setPlanUp(e.target.value);
    }

    let BudgetF = (e) => {
        // console.log(e.target.value);
        setBudget(e.target.value);
    }

    let ActivitiesF = (e) => {
        let data = Activities;
        data.push(e.target.value);
        setActivities(data)
        // console.log(data)

    }
    let TourF1 = (e) => {
        // console.log(e.target.value)
        setTourGuides(e.target.value);
    }

    let FoodF = (e) => {
        let data = foodType;
        data.push(e.target.value)
        setFoodType(data)
        // console.log(data)
    }


    const checkValidation = () => {
        const validationErrors = {};

        if (!searchTerm) {
            validationErrors.searchTerm = 'Start Destination field is required';
            testRef.current.focus()
        } else {
            validationErrors.searchTerm = ""
        }

        if (!searchTerm1) {
            validationErrors.searchTerm1 = 'End Destination field is required';
            testRef1.current.focus()
        } else {
            validationErrors.searchTerm1 = ""
        }


        if (!travelDate) {
            validationErrors.travelDate = 'Travel Date field is required';
           
        } else {
            validationErrors.travelDate = ""
        }

        if (!travelWith) {
            validationErrors.travelWith = 'Who do you plan on traveling with on your next adventure? field is required';
        } else {
            validationErrors.travelWith = ""
        }
        if (!Budget) {
            validationErrors.Budget = 'What is Your Budget field is required';
        } else {
            validationErrors.Budget = ""
        }
        if (!selectedActivities[0]) {
            validationErrors.selectedActivities = 'Which activities are you interested in? field is required';
        } else {
            validationErrors.selectedActivities = ""
        }
        if (!tourGuides) {
            validationErrors.tourGuides = 'Tour gide field is required';
        } else {
            validationErrors.tourGuides = ""
        }

        if (!foodType[0]) {
            validationErrors.foodType = 'Food Type field is required';
        } else {
            validationErrors.foodType = ""
        }

        setErrors(validationErrors);
        return Object.values(validationErrors).every((error) => error === '');
    };



    const handleSubmit = async (e) => {
        e.preventDefault()
        // localStorage.removeItem("jsonData")
        const isValid = checkValidation();
        //  console.log(isValid)
        if (!isValid) {
            // testRef.current.focus();
            return false

        }
        setShowLoader(true)
        let data = {
            startDestination: searchTerm,
            endDestination: searchTerm1,
            travelDate: travelDate,
            numOfDays: numOfDays,
            PlanupState: travelWith,
            Budget: Budget,
            Activities: selectedActivities,
            tourGuides: tourGuides,
            foodType: foodType
        }
        
        // console.log(data)
        
        // setTravelData(data);
        try {
            // const res = await axios.post(`${processs.env.REACT_APP_BACKEND_URL}/chatGpt`, data);
            
            
            if (data) {
                // console.log("trippage", data)
                setTravelData(data)
                setTimeout(() => {
                    localStorage.setItem("TripPlanData", true)
                    setShowLoader(false);
                    navigate("/preview");

                }, 2000);
                // localStorage.setItem('myData', jsonData);

            } else {
                console.log("Request was not successful");

            }

        } catch (error) {
            console.error("Error while making the request:", error);

        }



        //     let res = await axios.post("http://localhost:4000/chatGpt", data);
        //     if (!res) console.log("data not found")
        //     // console.log(res)
        //     // setResponse(res?.data?.message)
        //  let jsonData=(res?.data?.message) ? JSON.stringify(res?.data?.message):JSON.stringify({})
        // localStorage.setItem('myData',jsonData);
        // // console.log(jsonData)
        //     setShowLoader(false)
        //     navigate("/preview")
        // if(typeof res?.data?.message==='string'){
        //     console.log(res.message)
        // }else{

        //     localStorage.setItem('myData',JSON.stringify({data:res.message}));
        //     navigate("/preview")
        // }
        // let data1=res?.data?.message
        // let jsonData=json.loads(res?.data?.message);
        // console.log({data:res?.data?.message})


        // Send user message to ChatGPT (You'll need to replace 'YOUR_API_KEY' with your actual OpenAI API key)
        //      let userMessage = `I have plan to visit ${data.endDestination} from ${data.startDestination} with ${data.PlanupState} for ${data.numOfDays} days having budget ${data.Budget} .`
        //      let userMessage2 = `I would likely to start on ${data.travelDate} .`
        //      let userMessage3 = `I want to do these activites ${data.Activities}.`
        //      let userMessage4 = `provide me itineries with places to visit with some description, can you  return response in JSON format as per day, place, place description, distance to next place`
        //      let userMessage5 = ` I need cost estimates for whole journey, weather update, climate contions etc.`
        //      let systemrMessage = `Can you provide me itinery with places to visits with some description for this travel.can you  return me response in JSON format as per day, place, place description, distance to next place in separate embeddings`

        // const response = await axios.post('https://api.openai.com/v1/chat/completions', {
        //     model: 'gpt-3.5-turbo',
        //     messages: [
        //       { role: 'system', content: 'You are a travel assistant.' },

        //       { role: 'user', content: msg},
        //     //   { role: 'user', content: userMessage2 },
        //     //   { role: 'user', content: userMessage3 },
        //     //   { role: 'user', content: userMessage4 },
        //     //   { role: 'user', content: userMessage5 },
        //     //   { role: 'system', content: systemrMessage },
        //     ],
        //   }, {
        //     headers: {
        //       'Content-Type': 'application/json',
        //       'Authorization': 'Bearer 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',
        //     },
        //   });

        //   // Extract the model-generated message (response.data.choices[0].message.content)
        //   const assistantMessage = response.data.choices[0].message.content;
        //   console.log(assistantMessage)
        // Extract and use the embeddings (response.data.choices[0].message.embedding)
        //   const embeddings = response.data.choices[0].message.embedding;
        //   console.log('response  ',response);
        //   console.log('embeddings  ',embeddings);
        //   console.log('assistantMessage  ',assistantMessage);
        // setResponse(assistantMessage)
    };





    const travelNextAdventure = (name) => {
        setTravelWith(name)
    }
    const budgetInputHandler = (name) => {
        setBudget(name)
    }

    const toggleActivity = (activity) => {
        if (selectedActivities.includes(activity)) {
            setSelectedActivities(selectedActivities.filter(item => item !== activity));
        } else {
            setSelectedActivities([...selectedActivities, activity]);
        }
    };
    return (
        <div>
            <div className='trip-plan-container container'>
                <div className='form-container'>
                    <div className='form-content-box'>
                        <h1>Tell us your travel preferences
                        </h1>
                    </div>
                    <div className='form-box '>
                        <form className='form' onSubmit={handleSubmit}>
                            <div className='interested'>
                                <div className='start_end'>
                                <div className='form-group'>
                                    <label>Start Destination</label>
                                    <input
                                        ref={testRef}
                                        type="text"
                                        className='form-control'
                                        id='startDestination'
                                        value={searchTerm}
                                        placeholder='Enter the Start Destination..'
                                        onChange={(e) => handleStartDestinationChange(e.target.value)}
                                        autoComplete='off'
                                    />
                                    {/* <AutocompleteComponent /> */}
                                    {errors?.searchTerm && (
                                        <div className="error-message-box">
                                            <span >{errors?.searchTerm}</span>
                                        </div>
                                    )}
                                    {
                                        // showDropdown && (
                                        //     <ul className='search-option-box'>
                                        //         {/* {console.log(searchResults,"?????") 
                                        //         {searchResults?.city?.map((item, index) => (
                                        //             <li onClick={(e) => SelectCities(item)} value={item} key={index} className={item === searchTerm ? 'selected' : ''} >{item},{searchResults.country}</li>
                                        //         ))
                                        //         } */}
                                        //     </ul>
                                        // )
                                    }

                                </div>
                                <div className='form-group'>
                                    <label >End Destination</label>
                                    <input
                                        type="text"
                                        className='form-control'
                                        id='endDestination'
                                        ref={testRef1}
                                        value={searchTerm1}
                                        placeholder='Enter the End Destination..'
                                        onChange={(e) => handleEndDestinationChange(e.target.value)}
                                        autoComplete='off'
                                    />
                                    {errors?.searchTerm1 && (
                                        <div className="error-message-box">
                                            <span >{errors?.searchTerm1}</span>
                                        </div>
                                    )}
                                    {showDropdown1 && (
                                        <ul className='search-option-box'>
                                            {/* {console.log(searchResults,"?????")  */}
                                            {searchResults1?.city?.map((item, index) => (
                                                <li onClick={(e) => SelectCities1(item)} value={item} key={index}>{item},{searchResults1.country}</li>
                                            ))
                                            }
                                        </ul>
                                    )}
                                </div>

                                </div>
                                {/* <div className='divider'></div> */}
                                <div className='form-group'>
                                    <label htmlFor='planDate' >When are you planning to travel?</label>
                                    <DatePicker placeholderText='Select Date..'  autoComplete='off' className='date-picker-input ' id={"planDate"} selected={travelDate} onChange={(date) => setTravelDate(date)} />

                                    {/* Implement a date picker component here */}
                                    {errors?.travelDate && (
                                        <div className="error-message-box">
                                            <span >{errors?.travelDate}</span>
                                        </div>
                                    )}
                                </div>
                                <div className='divider'></div>
                                <div className='form-group'>
                                    <label >How many days are you planning to travel?</label>
                                    {/* <input
                                    type="number"
                                    className='form-control'
                                    id='planDays'
                                    value={numOfDays}
                                    onChange={(e) => handleNumOfDaysChange(parseInt(e.target.value))}
                                /> */}
                                    <div className='day'>
                                        <div >Day</div>

                                        <div className="bttns-box mt-3 flex items-center gap-2">
                                            <button type='button' className="btn btn-primary me-2" onClick={() => handleNumOfDaysChange(numOfDays - 1)}>-</button>
                                            <div className="num">{numOfDays}</div>
                                            <button type='button' className="btn btn-primary ms-2" onClick={() => handleNumOfDaysChange(numOfDays + 1)}>+</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className='divider'></div>
                            <div className='form-group'>
                                <label >Who do you plan on traveling with on your next adventure?</label>
                                <div className='form_budget adventure-container'>
                                    <div className={`radio budget ${travelWith === "Solos" ? "active" : ""}`} onClick={() => travelNextAdventure("Solos")}>
                                        <img src={require("../assets/images/backpacker-svg.png")} alt=''></img>
                                        <label  >
                                            Solos
                                        </label>
                                        <input type="radio" id='abc' value="Solos" checked={PlanupState === "Solos"} onChange={PlanUp} />
                                    </div>
                                    <div className={`radio budget ${travelWith === "Couple" ? "active" : ""}`} onClick={() => travelNextAdventure("Couple")}>
                                        <img src={require("../assets/images/couple-svg.png")} alt=''></img>
                                        <label >
                                            Couple
                                        </label>
                                        <input type="radio" id="abd" value="Couple" checked={PlanupState === "Couple"} onChange={PlanUp} />
                                    </div>
                                    <div className={`radio budget ${travelWith === "Family" ? "active" : ""}`} onClick={() => travelNextAdventure("Family")}>
                                        <img src={require("../assets/images/family-svg.png")} alt=''></img>
                                        <label>
                                            Family
                                        </label>
                                        <input type="radio" value="Family" checked={PlanupState === "Family"} onChange={PlanUp} />
                                    </div>
                                    <div className={`radio budget ${travelWith === "Friends" ? "active" : ""}`} onClick={() => travelNextAdventure("Friends")}>
                                        <img src={require("../assets/images/friends-svg.png")} alt=''></img>
                                        <label>
                                            Frinds
                                        </label>
                                        <input type="radio" value="Friends" checked={PlanupState === "Friends"} onChange={PlanUp} />
                                    </div>
                                </div>
                                {errors?.travelWith && (
                                    <div className="error-message-box">
                                        <span >{errors?.travelWith}</span>
                                    </div>
                                )}
                            </div>
                            <div className='divider'></div>

                            <div className='form-group'>
                                <label>What is Your Budget?</label>
                                <div className='form_budget' >
                                    <div className={`radio budget ${Budget === "2k-10k" ? "active" : ""}`} onClick={() => budgetInputHandler("2k-10k")}>
                                        <img src={require("../assets/images/money-svg.png")} alt=''></img>
                                        <label>
                                            2k-10k
                                        </label>
                                        <input type="radio" value="2k-10k" checked={Budget === '2k-10k'} onChange={BudgetF} />
                                    </div>
                                    <div className={`radio budget ${Budget === "10k-30k" ? "active" : ""}`} onClick={() => budgetInputHandler("10k-30k")}>
                                        <img src={require("../assets/images/money-svg.png")} alt=''></img>
                                        <label>
                                            10k-30k
                                        </label>
                                        <input type="radio" value="10k-30k" checked={Budget === '10k-30k'} onChange={BudgetF} />
                                    </div>
                                    <div className={`radio budget ${Budget === "30k-50k" ? "active" : ""}`} onClick={() => budgetInputHandler("30k-50k")}>
                                        <img src={require("../assets/images/money-svg.png")} alt=''></img>
                                        <label>
                                            30k-50k
                                        </label>
                                        <input type="radio" value="30k-50k" checked={Budget === '30k-50k'} onChange={BudgetF} />
                                    </div>
                                </div>
                                {errors?.Budget && (
                                    <div className="error-message-box">
                                        <span >{errors?.Budget}</span>
                                    </div>
                                )}
                            </div>
                            <div className='divider'></div>

                            <div className='form-group' >
                                <label>Which activities are you interested in?</label>
                                <div className='form_budget'>
                                    <div className={`radio budget ${selectedActivities.includes("Beaches") ? "active" : ""}`} onClick={() => toggleActivity("Beaches")} >
                                        <img src={require("../assets/images/beaches-svg.png")} alt='' ></img>
                                        <input value="Beaches" type="checkbox" onChange={ActivitiesF} />
                                        <span> Beaches</span>
                                    </div>
                                    <div className={`radio budget ${selectedActivities.includes("City sightseeing") ? "active" : ""}`} onClick={() => toggleActivity("City sightseeing")}  >
                                        <img src={require("../assets/images/city-svg.png")} alt=''></img>
                                        <input value="City sightseeing" type="checkbox" onChange={ActivitiesF} />
                                        <span>City sightseeing</span>
                                    </div>
                                    <div className={`radio budget ${selectedActivities.includes("Outdoor adventures") ? "active" : ""}`} onClick={() => toggleActivity("Outdoor adventures")} >
                                        <img src={require("../assets/images/hiking-svg.png")} alt=''></img>
                                        <input value="Outdoor adventures" type="checkbox" onChange={ActivitiesF} />
                                        <span>Outdoor adventures</span>
                                    </div>
                                    <div className={`radio budget ${selectedActivities.includes("Festival/events") ? "active" : ""}`} onClick={() => toggleActivity("Festival/events")} >
                                        <img src={require("../assets/images/festival-svg.png")} alt=''></img>
                                        <input value="Festival/events" type="checkbox" onChange={ActivitiesF} />
                                        <span>Festival/events </span>
                                    </div>
                                    <div className={`radio budget ${selectedActivities.includes("Food exploration") ? "active" : ""}`} onClick={() => toggleActivity("Food exploration")} >
                                        <img src={require("../assets/images/restaurant2-svg.png")} alt=''></img>
                                        <input value="Food exploration" type="checkbox" onChange={ActivitiesF} />
                                        <span> Food exploration</span>
                                    </div>
                                    <div className={`radio budget ${selectedActivities.includes("Nightlife") ? "active" : ""}`} onClick={() => toggleActivity("Nightlife")} >
                                        <img src={require("../assets/images/nightlife-svg.png")} alt=''></img>
                                        <input value="Nightlife" type="checkbox" onChange={ActivitiesF} />
                                        <span>Nightlife</span>
                                    </div>
                                    <div className={`radio budget ${selectedActivities.includes("Shopping") ? "active" : ""}`} onClick={() => toggleActivity("Shopping")} >
                                        <img src={require("../assets/images/online-shopping-svg.png")} alt=''></img>
                                        <input value="Shopping" type="checkbox" onChange={ActivitiesF} />
                                        <span>Shopping</span>
                                    </div>
                                    <div className={`radio budget ${selectedActivities.includes("Spa welleness") ? "active" : ""}`} onClick={() => toggleActivity("Spa welleness")} >
                                        <img src={require("../assets/images/massage-svg.png")} alt=''></img>
                                        <input value="Spa welleness" type="checkbox" onChange={ActivitiesF} />
                                        <span> Spa welleness</span>
                                    </div>
                                </div>
                            {errors?.selectedActivities && (
                                <div className="error-message-box">
                                    <span >{errors?.selectedActivities}</span>
                                </div>
                            )}
                            </div>
                            <div className='divider'></div>
                        <div className='bootom_box'>
                            <div className='form-group' >
                                <label >Tour gide?</label>
                                <div className="radio">
                                    <div className='check-boxes'>
                                        
                                        <input type="radio" id='y1' value="Yes" checked={tourGuides === "Yes"} onChange={TourF1} />
                                        <label htmlFor='y1'><span>Yes</span></label>
                                    </div>
                                </div>
                                <div className="radio">
                                    <div className='check-boxes'>
                                        <input type="radio" id='n1' value="No" checked={tourGuides === "No"} onChange={TourF1} />
                                        <label htmlFor='n1'><span>No</span></label>
                                    </div>
                                </div>
                                {errors?.tourGuides && (
                                    <div className="error-message-box">
                                        <span >{errors?.tourGuides}</span>
                                    </div>
                                )}

                            </div>

                            <div className='form-group'>
                                <label>Would you like to have these options?</label>
                                <div className='food_option'>
                                    <input value="Vegetarian" id="veg" type="checkbox" onChange={FoodF} />
                                   <label htmlFor='veg'><span>Vegetarian</span></label>
                                </div>
                                <div className='food_option'>
                                    <input value="None-Vegetarian" id="non_v" type="checkbox" onChange={FoodF} />
                                   <label htmlFor='non_v'> <span>None-Vegetarian</span></label>
                                </div>
                                {errors?.foodType && (
                                    <div className="error-message-box">
                                        <span >{errors?.foodType}</span>
                                    </div>
                                )}

                            </div>
                            </div>

                            <div className='submit_button'>
                                <button className='btn btn-primary d-flex align-items-center ' type="submit" disabled={showLoader}><span> Submit</span> {showLoader && (<div className=" ms-2 spinner-border text-white" role="status">
                                </div>)}</button>
                            </div>
                        </form>
                    </div>
                    {/* {navigate("/preview")} */}
                    <div style={{display:'none'}}>
            {travelData && <Preview travelData={travelData} />}
            </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default TripPlan;
