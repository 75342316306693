// import { useState } from 'react';
// import Carousel from 'react-bootstrap/Carousel';
/* The following line can be included in a src/App.scss */
import 'bootstrap/dist/css/bootstrap.min.css';
// import './custom.scss';
import '@coreui/coreui/dist/css/coreui.min.css'
import { CCarousel } from '@coreui/react'
import { CCarouselCaption } from '@coreui/react'
import { CCarouselItem,CImage } from '@coreui/react'
// import 'bootstrap/dist/css/bootstrap.min.css'
  // Override default variables before the import


// import ExampleCarouselImage from 'components/ExampleCarouselImage';
function ControlledCarousel(){

  return (
    <CCarousel controls indicators dark>
  <CCarouselItem>
    <CImage className="d-block w-100" src={require("../assets/images/slider-1.jpg")} alt="slide 1" />
    <CCarouselCaption className="d-none d-md-block">
    <h1> You worry about the fun, we'll worry <span>about the planning.</span></h1>
    <p>Just tell us what you're looking for, and we'll take care of all the exhausting stuff, like place research and accommodations.
    So you can focus on what's important: having fun!
    </p>
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage className="d-block w-100" src={require("../assets/images/slider-banner-2.jpg")} alt="slide 2" />
    <CCarouselCaption className="d-none d-md-block">
    <h1> You worry about the fun, we'll worry <span>about the planning.</span></h1>
    <p>Just tell us what you're looking for, and we'll take care of all the exhausting stuff, like place research and accommodations.
    So you can focus on what's important: having fun!
    </p>
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage className="d-block w-100" src={require("../assets/images/banner-3.jpg")} alt="slide 3" />
    <CCarouselCaption className="d-none d-md-block">
    <h1> You worry about the fun, we'll worry <span>about the planning.</span></h1>
    <p>Just tell us what you're looking for, and we'll take care of all the exhausting stuff, like place research and accommodations.
    So you can focus on what's important: having fun!
    </p>
   
    </CCarouselCaption>
  </CCarouselItem>
</CCarousel>
  );
}

export default ControlledCarousel;