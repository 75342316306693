import React from "react";
import Footer from "../components/Footer"


let Blog = () => {
  return (
    <div className="full-Box">
      <div className="blog-hading">
        <h1>A Collection of Blog Posts, Announcements, and Inspiring Travel Stories</h1>
      </div>
      <div className="all-image-box">
      <div className="blog-image">
        <div className="image-box">
          <img src={require("../assets/images/family-travel-together.webp")} alt="" />
          <div className="artical">
            <h2>Family Wanderlust: Top 10 Family-Friendly Travel Destinations Worldwide</h2>
            <p>Explore top 10 family-friendly travel destinations worldwide - from Orlando's magic to Tokyo's fusion of tradition.</p>
          </div>
        </div>
        <div className="image-box">
          <img src={require("../assets/images/belem-tower.webp")} alt="" />
          <div className="artical">
            <h2>A Guide to Planning Your Family Trip to Portugal</h2>
            <p>This 'nuts and bolts' guide gives you the lowdown on planning a family trip to Portugal, covering everything from timing.</p>
          </div>
        </div>
        <div className="image-box">
          <img src={require("../assets/images/future-routes.webp")} alt="" />
          <div className="artical">
            <h2>How AI is Revolutionizing the Future of Vacation Planning</h2>
            <p>Artificial Intelligence (AI) is reshaping the travel and tourism industry, offering personalized travel recommendations.</p>
          </div>
        </div>


      </div>
      <div className="blog-image">
        <div className="image-box">
          <img src={require("../assets/images/travel-plane.webp")} alt="" />
          <div className="artical">
            <h2>How AI is Transforming the Future of Travel Planning</h2>
            <p>AI Will Transform Travel Planning in the Future with Personalized Recommendations, Real-Time Pricing, Itinerary.</p>
          </div>
        </div>
        <div className="image-box">
          <img src={require("../assets/images/wonderplan-july-summary.webp")} alt="" />
          <div className="artical">
            <h2>Wonderplan July Summary</h2>
            <p>A Milestone of 100k Views and Exciting Plans for the Future.</p>
          </div>
        </div>
        <div className="image-box">
          <img src={require("../assets/images/future-of-travel-planning.webp")} alt="" />
          <div className="artical">
            <h2>Experience the Future of Travel Planning: 3 Must-Try AI-Powered Tools at No Cost</h2>
            <p>AI-powered travel planning tools can generate personalized itineraries in seconds, saving you time and effort.</p>
          </div>
        </div>
      </div>
      </div>
      <Footer />
    </div>
  )

}

export default Blog;


