import React from "react";
import { Link } from "react-router-dom";
import ControlledCarousel from "../components/Carousel"
import Footer from "./Footer";

const Home = () => {

    return (
        <div className="home-container">
            <div className="banner_slider">
                        <ControlledCarousel />
                        </div>
            <div className="container">
                <div className="banner-container ">
                <div className="row about_image">
                <div className="col-sm-6">
                    <div className="banner-box">
                        <div className="banner-heading">
                            <h1>Experience travel more than ever with artificial intelligence's insight
</h1>
                        </div>
                        <div className="banner-sub-heading">
                            <h6>RND Experts is your own movement keeper, forming remarkable agendas around your interests and wallet.
 </h6>
                        </div>
                    </div>
                        <div className="button-box">
                          <button>  <Link to={"/trip-plan"}>Get started--it's free</Link></button>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="user-friendly">
                            <img src={require("../assets/images/usernew.png") } alt="" />
                        </div>
                        </div>
                        </div>
                        <div className="banner-img-box">
                        <div className="img-box">
                            <img src={require("../assets/images/webPage.png") }alt="" />
                        </div>
                    </div>
                    <div className="banner-text-box">
                        <h1>Finally, No more travel research hassle</h1>
                    </div>
                </div>


                <div className="services-container ">
                    <div className="row">
                        <div className="col-md-6 service-box-1">
                            <div className="heading">
                                <h1>Personalized Itinerary</h1>
                            </div>
                            <div className="content">
                                <p>We'll create a custom itinerary that's tailored to your interests, budget, and time constraints.

                                </p>
                            </div>
                            <div className="img-box">
                            <img src={require("../assets/images/user1-svg.png") } alt="" />
                            </div>
                        </div>
                        <div className="col-md-6 service-box-2">
                            <div className="heading">
                                <h1>Optimal Route</h1>
                            </div>
                            <div className="content">
                                <p>We help you find the best way to get from one place to another, considering distance, traffic, and your interests.

                                </p>
                            </div>
                            <div className="img-box">
                            <img src={require("../assets/images/user2-svg.png") } alt="" />
                            </div>
                        </div>
                    </div>
                  <div className="start_free"> 
                    <h1>Get started for free</h1>
                  </div>
                  <div className="button-box">
                          <button>  <Link to={"/trip-plan"}>Try Trip- plan now</Link></button>
                        </div>

                       
                </div>
                <div className="img-box">
                            <img src={require("../assets/images/user3-svg.png") } alt="" />
                            </div>
            </div>
            <Footer/>

        </div>)
}
export default Home