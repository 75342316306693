import { React, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners"
import { useDispatch } from 'react-redux';
import { loginSuccess } from "../store/actions/Auth";


let Login = () => {
  const dispatch = useDispatch()
  // const frontendURL = process.env.REACT_APP_FRONT;
 
  let navigate = useNavigate()
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [errors, setErrors] = useState({});
  const [loading1, setLoading1] = useState(false);
  const [resMessage, setResponseMessage] = useState("");
  const [color, setColor] = useState("text-danger")

  let inputHandler = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }
  const checkValidation = () => {
    const validationErrors = {};

    if (!formData.email) {
      validationErrors.email = 'Email address field is required';
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      validationErrors.email = 'Please enter a valid email address';
    }

    if (!formData.password) {
      validationErrors.password = 'Password field is required';
    } else if (formData.password.length < 6) {
      validationErrors.password = 'Password must be at least 6 characters';
    }

    setErrors(validationErrors);

    return Object.keys(validationErrors).length === 0;
  };


  let onsubmit = async (e) => {
    setLoading1(true)
    e.preventDefault();
    const isValid = checkValidation();
    if (!isValid) {
      setLoading1(false)
      return false
    }

    let response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/login`, formData);
    try {
      console.log(response?.data?.message);

      // if (response) {

        if (response?.data?.message ==='The user has been logged in successfully.') {
          setLoading1(false)
          setColor(true)
          localStorage.setItem("userName",response?.data?.name)
          localStorage.setItem("id", response?.data?.id);
          dispatch(loginSuccess(response?.data?.token)); 
          let TripPlanData = localStorage.getItem("TripPlanData");
          let token = localStorage.getItem("token");
        
          if (TripPlanData && token) {
            setResponseMessage(response?.data?.message);
            setTimeout(() => {
              navigate("/preview")
            }, 1000);
          } else {
            setResponseMessage(response?.data?.message);
            setTimeout(() => {
              navigate("/")
            }, 1000);
          }
        
        } else {
          setResponseMessage(response?.data?.message);
          setLoading1(false)
        }
      
    } catch (error) {
      console.log(error.message)
      setLoading1(false)
    }

  }

  return (

    <div className="auth-container">
      <div className="container form-container login-form">
        <h1>Login</h1>
        <form onSubmit={onsubmit}>
          <div className="form-group">

            <label htmlFor='email'>Email</label>
            <input className="form-control"
              type='text'
              name='email'
              id='email'
              onChange={inputHandler}
            />
            {errors?.email && (
              <div className="error-message-box">
                <span >{errors?.email}</span>
              </div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor='password'>Password</label>
            <input className="form-control"
              type='password'
              name='password'
              id='password'
              onChange={inputHandler}
            />
            {errors?.password && (
              <div className="error-message-box">
                <span >{errors?.password}</span>
              </div>
            )}
          </div>
          <div className="submit-btn">
            <button type='submit'>Sign in</button>
          </div>
          <div className="account-box text-center mt-3">
            {loading1 ? <ClipLoader /> : color === 'text-danger' ? <div className="account-box text-center text-danger">
              {resMessage}
            </div> : <div className="account-box text-center text-success">
              {resMessage}
            </div>}
            </div>
          <div className="account-box text-center mt-3">
          <a href={`https://traveler.rndexperts.in/forgot-password`}>Forgot password?</a>
          </div>
          <div className="account-box text-center mt-3">
            <span>Don't have an account? <Link to="/register">Sign up</Link></span>
          </div>

        </form>
      </div>
    </div>
  )
}

export default Login;