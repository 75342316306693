import { React, useState } from "react";
import axios from "axios";
// import { Link, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners"

let ForgotPassword = () => {
 
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [errors, setErrors] = useState({});
  const [loading1, setLoading1] = useState(false);
  const [resMessage, setResponseMessage] = useState("");
  const [color, setColor] = useState("text-danger")

  let inputHandler = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }

  const checkValidation = () => {
    const validationErrors = {};

    if (!formData.email) {
      validationErrors.email = 'Email address field is required';
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      validationErrors.email = 'Please enter a valid email address';
    }

   
    setErrors(validationErrors);

    return Object.keys(validationErrors).length === 0;
  };


  let onsubmit = async (e) => {
    setLoading1(true)
    e.preventDefault();
    const isValid = checkValidation();
    if (!isValid) {
      setLoading1(false)
      return false
    }
   
    let response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/forgot-password`, { email: formData.email });
    try {
        setLoading1(true)
        console.log("loading 1", loading1)
    
        if (response?.data) {
          setLoading1(false)
          setColor("text-success")
          setResponseMessage(response?.data?.message)
        } else {
          setLoading1(true)
          setResponseMessage(response?.data?.message)
        }

      
    } catch (error) {
      // console.log(error.message)
      setLoading1(false)
    }

  }

  return (

    <div className="auth-container">
      <div className="container form-container login-form">
        <h1>Forgot pasword</h1>
        <form onSubmit={onsubmit}>
          <div className="form-group">

            <label htmlFor='email'>Email</label>
            <input className="form-control"
              type='text'
              name='email'
              id='email'
              onChange={inputHandler}
            />
            {errors?.email && (
              <div className="error-message-box">
                <span >{errors?.email}</span>
              </div>
            )}
          </div>
          <div className="submit-btn">
            <button type='submit' disabled={loading1}>Forgot pasword</button>
          </div>
          <div className="account-box text-center mt-3">
            {loading1 ? <ClipLoader /> : color === 'text-danger' ? <div className="account-box text-center text-danger">
              {resMessage}
            </div> : <div className="account-box text-center text-success">
              {resMessage}
            </div>}
          </div>

        </form>
      </div>
    </div>
  )
}

export default ForgotPassword;