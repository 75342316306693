
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';

function Profile() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    mobile: '',
  });
  const [loading, setLoading] = useState(false);
  const [resMessage, setResMessage] = useState('');
  const [color, setColor] = useState('text-danger');
  const [errors, setErrors] = useState({});

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/get-profile/${localStorage.getItem('id')}`
        );

        const { data } = response.data;
        setFormData({
          ...formData,
          username: data.userName,
          email: data.email,
          mobile: data.mobile,
        });
      } catch (error) {
        console.error(error);
        // Handle errors here, e.g., show an error message to the user
      }
    }

    fetchData();

  }, []);

  const checkValidation = () => {
    const validationErrors = {};
  
    if (!formData.username) {
      validationErrors.username = 'Username field is required';
    } else if (!/^[a-zA-Z0-9_]+$/.test(formData.username)) {
      validationErrors.username = 'Please enter a valid username';
    }
      
    if (!formData.mobile) {
      validationErrors.mobile = 'Mobile number field is required';
    } else if (!/^[0-9]{10}$/.test(formData.mobile)) {
      validationErrors.mobile = 'Please enter a valid 10-digit mobile number';
    }
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  }


  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const isValid = checkValidation();
    if (!isValid) {
      setLoading(false)
      return false
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/update-profile`, {
        id: localStorage.getItem('id'),
        userName: formData.username,
        password: formData.password,
        mobile: formData.mobile,
      });
      console.log(response.data.message,"msg")
      
      if (response?.data) {
        setColor('text-success');
        // localStorage.removeItem('token');
        // localStorage.removeItem('id');
        setResMessage(response.data.message);
        
        setTimeout(() => {
          navigate('/');
          window.window.location.reload(false);
        }, 500);
      } else {
        setColor('text-danger');
        setResMessage('Password reset failed');
      }
    } catch (error) {
      console.error(error);
      setColor('text-danger');
      setResMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="auth-container">
      <div className="container form-container login-form">
        <h1>Profile</h1>
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label htmlFor="username">User name</label>
            <input
              className="form-control"
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={inputHandler}
            />
            {errors?.mobile && (
              <div className="error-message-box">
                <span >{errors?.username}</span>
              </div>
            )}
          </div>

          <div className="form-group" >
            <label htmlFor="email">Email</label>
            <input
              className="form-control"
              type="text"
              
              id="email"
              name="email"
              disabled="true"
              value={formData.email}
              onChange={inputHandler}
            />
          </div>

          <div className="form-group">
            <label htmlFor="mobile">Mobile</label>
            <input
              className="form-control"
              type="text"
              id="mobile"
              name="mobile"
              value={formData.mobile}
              onChange={inputHandler}
            />
               {errors?.mobile && (
              <div className="error-message-box">
                <span >{errors?.mobile}</span>
              </div>
            )}
          </div>

          <div className="submit-btn">
            <button type="submit" disabled={loading}>
              Update profile
            </button>
          </div>
        </form>
        <div className="account-box text-center mt-3">
          {loading ? (
            <ClipLoader />
          ) : (
            <div className={`account-box text-center ${color}`}>{resMessage}</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Profile;
