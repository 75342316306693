
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import Login from './Login';

function ShowModal(props) {
    let navigate=useNavigate();
    const [show, setShow] = useState(false);
    
    
    console.log("pppp",props)
    useEffect(()=>{
      if(props?.message){
        setShow(true)
   
      }
        
    },[props?.message])
    
  const handleClose = () => {
    setShow(false);
  }
 let ShowLoginPage=()=>{
    navigate("/login")
 }

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Congratulations!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Welcome! {props.message}. Please click the 'Sign in' button to access your account.
            <Button variant="primary" onClick={ShowLoginPage}>
            Sign in
            </Button>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
        </Modal.Footer>
      </Modal >
    </>
  );
}

export default ShowModal;


// import { useEffect, useState } from 'react';
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
// import { useNavigate } from 'react-router-dom';
// // import Login from './Login';

// function ShowModal(props) {
//     let navigate=useNavigate();
//     const [show, setShow] = useState(false);
    
//     console.log("pppp",props)
//     useEffect(()=>{
//         setShow(props.status)
        
//     },[props.status])
    
//   const handleClose = () => {
//     setShow(false);
//   }
//  let ShowLoginPage=()=>{
//     navigate("/login")
//  }

//   return (
//     <>
//       {/* <Button variant="primary" onClick={handleShow}>
//         Launch demo modal
//       </Button> */}
//       <Modal show={show} onHide={handleClose}>
//         <Modal.Header closeButton>
//           <Modal.Title>Congratulations!</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>Welcome! Your registration is complete. Please click the   <Button variant="primary" onClick={ShowLoginPage}>
//             Sign in
//             </Button>   button to access your account.
            
//         </Modal.Body>
//         <Modal.Footer>
//           {/* <Button variant="secondary" onClick={handleClose}>
//             Close
//           </Button> */}
//         </Modal.Footer>
//       </Modal >
//     </>
//   );
// }

// export default ShowModal;